import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Btn from "../components/button"

const ThankYouPage = ({ location }) => (
  
<>
  {/* Hero section  */}
  <Layout
    noContact
    breadcrumb={
      {"Danke": "/danke"}
    }
  >
    <Seo 
      title="Danke für die Kontaktanfrage | Gartenfreunde Offenburg e.V."
      description="Danke für Ihre Kontaktanfrage. Wir melden uns schon ganz bald bei Ihnen."
      lang="de"
      pathname={location.pathname}
      robots="noindex,follow"
    />
    <Section>
      <div className="row">
        <div className="col-md-8">
          <h1 >Vielen Dank für Ihre Kontaktanfrage.</h1>
          <p className="subheading align-left">Wir melden uns schon ganz bald zurück.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 d-flex justify-content-around justify-content-md-start">
          <Btn
            type="secondary"
            text="zurück zur Startseite"
            href="/"
          />
        </div>
      </div>
    </Section>
    
  </Layout>
</>
)
export default ThankYouPage
